<script>
import Layout from "../../layouts/main";
import QuizLayout from '../../layouts/quizLayout';
import errorHandler from '../../../helpers/errorHandler';
import ConfirmActionDialog from "@/components/ConfirmActionDialog";
import ExportExcel from '../../../components/exportExcel.vue';
// import moment from 'moment';

/**
 * Starter component
 */
export default {
  page: {
    title: "Quiz Info",
  },
  name: "Quiz-details",
  components:{
    Layout,
    QuizLayout,
    ConfirmActionDialog,
    ExportExcel
  }, 
  async created(){
    await this.$store.dispatch('quiz/getQuizInfo',this.$route.params.quiz).then(()=>{
        this.profileData = this.$store.getters['quiz/getQuiz'];
    }).catch(err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch('quiz/getQuizCount',this.$route.params.quiz).then(()=>{
        this.count = this.$store.getters['quiz/getQuizCount'];
    }).catch(err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
  },
  computed: {
    tableFields() {
      return this.fields.filter(f => f.selected);
    },
    questions() {
      return this.$store.getters["quiz/allQuestions"]?this.$store.getters["quiz/allQuestions"] : [];
    },
    rows() {
      return this.questions.length;
    }
  },
  data() {
    return {
      profileData:{},
      count:0,
      recordId: null,
      title: "Quizes List",
      items: [
        {
          text: "Work Center",
          href: "/"
        },
        {
          text: "Quizes",
          href: "/quizes"
        }
      ],
      perPage: 20,
      currentPage: 1,
      pageOptions: [10, 20, 30],
      filter: "",
      sortBy: "number",
      sortDesc: false,
      filterOn: ["number","name","description"],
      fields: [
        {
          key: "question_text",
          label: "Question name",
          sortable: true,
          selected: true
        },
        {
          key: "grade",
          label: "Grade",
          sortable: true,
          selected: true
        },
        {
          key: "action",
          label: "",
          sortable: false,
          selected: true,
          disabled: true
        }
      ]
    };
  },
  methods: {
    RemoveRecord:function(){
     this.$store.dispatch("quiz/deleteQuestion",this.recordId).then((token)=>{
      errorHandler.methods.successful('Item deleted successfully!')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('deleteRecord')
    },
     showDeleteModal(id) {
      this.$bvModal.show('deleteRecord')
      this.recordId = id
    },
    viewProfile(record,index){
      this.$router.push('/quizes/' + record.id + '/details')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  },
//   filters:{
//     formatDate(value){
//       if(value){
//         return moment(String(value)).format('hh:mm A DD/MM/YYYY')
//       }
//     }
//   }
};
</script>

<template>
  <Layout>
    <QuizLayout :quiz="profileData" :count="count">
      <div class="row">
      <div class="col-lg-12">
        <div class="card" >
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;Records
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <router-link :to="`/quizes/${$route.params.quiz}/questions/create`" v-if="$store.getters['user/role'] == 0">
                    <b-button variant="success" class="btn-sm mr-2">
                      <i class="mdi mdi-plus"></i>
                      Add Question
                    </b-button>
                  </router-link>
                  <export-excel :jsonData="questions" v-if="$store.getters['user/role'] == 0"></export-excel>
                  <label class="d-inline-flex align-items-center mt-2">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                  <b-dropdown
                    id="dropdown-offset"
                    offset="250"
                    variant="link"
                    class="btn-icon-only"
                  >
                    <template #button-content>
                      <i class="fas fa-th-large iconGray"></i>
                    </template>
                    <b-dropdown-form>
                      <b-form-checkbox
                        v-for="(field, index) in fields"
                        :key="index"
                        class="mb-3"
                        v-model="field.selected"
                        :disabled="field.disabled"
                        >{{ field.label }}</b-form-checkbox
                      >
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </div>
              <!-- End search -->
            </div>
            <b-table
              table-class="table table-centered w-100 table-hover"
              thead-tr-class="bg-light"
              tbody-tr-class="clickableRow"
              :items="questions"
              :fields="tableFields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
            <template v-slot:cell(question_text)="data">
              <div v-html="data.item.question_text" class="question"></div>
            </template>
            <template v-slot:cell(chapter)="data">
              {{ data.item.chapter.name }}
            </template>
            <template v-slot:cell(give_chance)="data">
              <b-badge v-if="data.item.give_chance" variant="success" pill>Yes</b-badge>
              <b-badge v-else variant="danger" pill>No</b-badge>
            </template>
          <template v-slot:cell(action)="data" v-if="$store.getters['user/role'] == 0">
                <b-dropdown
                  class="btn-icon-onl"
                  right
                  toggle-class="arrow-none btn-light btn-sm"
                >
                  <template v-slot:button-content>
                    <i class="mdi mdi-dots-horizontal iconGray"></i>
                  </template>

                  <b-dropdown-item
                    :to="'/quizes/' + $route.params.quiz + '/questions/' + data.item.id + '/edit'"
                  >
                    <i
                      class="mdi mdi-pencil mr-2 text-muted font-18 vertical-middle iconGray"
                    ></i
                    >Edit
                  </b-dropdown-item>
                  <!-- <b-dropdown-item
                    :to="`/quizes/${$route.params.quiz}/questions/${data.item.id}/uploadImage`"
                    v-if="!data.item.question_img"
                  >
                    <i
                      class="mdi mdi-download mr-2 text-muted font-18 vertical-middle iconGray"
                    ></i
                    >Upload Question Image
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="`/quizes/${$route.params.quiz}/questions/${data.item.id}/uploadAnswerImage`"
                  >
                    <i
                      class="mdi mdi-download mr-2 text-muted font-18 vertical-middle iconGray"
                    ></i
                    >Upload Answer Image
                  </b-dropdown-item> -->
                  <!-- <b-dropdown-item
                    :to="`/quizes/${$route.params.quiz}/questions/${data.item.id}/deleteAnswerImage`"
                  >
                    <i
                      class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle iconGray"
                    ></i
                    >Delete answers image
                  </b-dropdown-item>
                  <b-dropdown-item
                 v-on:click="showDeleteModal(data.item.id)"
                  v-if="data.item.question_img"
                  ><i
                    class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle iconGray"
                  ></i
                  >Delete Question Image
                  </b-dropdown-item> -->
                  <b-dropdown-item
                 v-on:click="showDeleteModal(data.item.id)"
                  ><i
                    class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle iconGray"
                  ></i
                  >Delete
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="(filter == '' )?rows:totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="deleteRecord" size="md" title="Warning Action">
        <ConfirmActionDialog/>
        <template #modal-footer>
          <b-button variant="outline-success" @click="RemoveRecord()">Delete</b-button>
          <b-button variant="outline-danger" @click="$bvModal.hide('deleteRecord')">Cancel</b-button>
        </template>
    </b-modal>
    </QuizLayout>
  </Layout>
</template>
<style scoped>
.question::v-deep img {
  max-height: 250px !important;
  max-width: 250px !important;
}
</style>
